import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import arrowRight from "../assets/arrow-right-white.svg"
import arrowLeft from "../assets/arrow-left-white.svg"
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx';



const MdxPage = ({data, pageContext, location }) => {
    const post = data.mdx
    const siteTitle = data.site.siteMetadata.title
    const { previous, next } = pageContext
    const nextprevimage = data.allMdx.edges

return (
<Layout location={location} title={siteTitle}>
                  
<SEO
  title={post.frontmatter.title}
  description={post.frontmatter.description || post.excerpt}
/>
   
  <div id="hero" className="flex flex-col w-full mb4 relative">
    <GatsbyImage image={getImage(post.frontmatter.embeddedImagesLocal[0])}  className="w-full h-hero-fluid z-0" alt="..." style={{gridArea: "1/1", maxHeight: 700}} />
        <div className="w-full  py-6 z-30 -mt-20">
          <div className="bg-white w-11/12 sm:max-w-2xl  sm:mx-auto  md:max-w-3xl lg:max-w-4xl ">
              <div className="pl-6 pr-4 pt-8 w-11/12 sm:pt-12 sm:max-w-xl sm:mx-auto sm:px-6  md:max-w-2xl md:pr-32 lg:max-w-3xl  lg:pr-32  " >
                <div className="flex items-center mb-6">
                  <div className="h-2 w-16 bg-red-400 mr-3"></div>
                  <div className="text-xs uppercase tracking-xwide font-bold">{post.frontmatter.category}</div>
                </div>
                <div className="text-3xl leading-none tracking-wide mb-4 text-gray-800">{post.frontmatter.title}</div>
                <div className="text-lg  font-normal text-left tracking-wider leading-snug text-gray-700">{post.frontmatter.description}</div>
              </div>
          </div>
        </div>
  </div>

  <div id="editor"  className="outline-none bg-white mx-auto  mb-4 ">
      <MDXRenderer
      remoteImages={post.frontmatter.embeddedImagesRemote}
      localImages={post.frontmatter.embeddedImagesLocal}
      title={post.frontmatter.title}
      category={post.frontmatter.category}
      description={post.frontmatter.description}>
      {post.body}
      </MDXRenderer>
  </div>



<div className="flex py-12 bg-blue-600">
          <div className="flex flex-col sm:flex-row mx-auto">

              {previous && (
                  <div className="w-full p-4">
 
                    <Link className="" to={`/${previous.frontmatter.category}/${previous.frontmatter.title}`}>
                    <div className="flex flex-col  justify-center items-center ">
                     
                    {nextprevimage ? nextprevimage.filter( x => x.node.slug === previous.slug ).map( (x,i) =>  
                    <div key={'1.a.'+ i}>
                    <GatsbyImage image={getImage(x.node.frontmatter.embeddedImagesLocal[0])}  className="flex-none xl:rounded-full shadow-lg border-white border-4 h-32 w-32 object-cover p-4  overflow-hidden" 
                     alt="..."
                     tyle={{gridArea: "1/1", maxHeight: 500}} />
                    </div>) : ''}
                      
                    <div className="p-8 flex flex-col  justify-center items-center text-gray-100">
                      <div className="text-lg leading-tight tracking-wide  pb-1 text-center max-w-xs text-gray-100">{previous.frontmatter.title}</div>
                      <img className="w-6 h-6" src={arrowLeft} alt="arrow-right"/>
                    </div>


                    </div>
                    </Link>
                
                  </div>
              )}
 
              {next && (
                    <div className="w-full p-4">
                    
                    <Link className="" to={`/${next.frontmatter.category}/${next.frontmatter.title}`}>
                    <div className="flex flex-col  justify-center items-center ">

                    {nextprevimage ? nextprevimage.filter( x => x.node.slug === next.slug ).map( (x,i) =>  
                    <div key={'2.a.'+ i}>
                    <GatsbyImage image={getImage(x.node.frontmatter.embeddedImagesLocal[0])}  className="flex-none  shadow-lg border-white border-4 h-32 w-32 object-cover p-4  xl:rounded-full overflow-hidden" 
                     alt="..."
                     tyle={{gridArea: "1/1", maxHeight: 500}} />
                    </div>) : ''}
                              
                      <div className="p-8 flex flex-col  justify-center items-center text-gray-100">
                      <div className="text-lg leading-tight tracking-wide pb-1 text-center max-w-xs">{next.frontmatter.title}</div>
                      <img className="w-6 h-6 stroke-current" src={arrowRight} alt="arrow-right"/>
                      </div>
                    </div>
                    </Link>
                    
                    </div>
              )}

           </div>
          </div>


         
     
      </Layout>
    )

}




export const query = graphql`
  query($id: String) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        category
        description
        order
        embeddedImagesRemote {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      excerpt(pruneLength: 160)
      body
      slug
    }
    allMdx {
      edges {
        node {
          slug
          frontmatter {
            embeddedImagesLocal {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
          }
        }
      }
    }
  }
`;


export default MdxPage;